@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.login {
    background: #4285f4;
    padding: 16px;
    color: white;
    border: 2px solid #357ae8;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    animation: fadeIn 1s ease-in-out;
}

.login:hover {
    background-color: #357ae8;
    border-color: #2a65c7;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    animation: slideIn 0.5s ease-in-out;
}