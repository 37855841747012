.schedule {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.schedule h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.events {
    margin-top: 20px;
}

.event {
    background-color: #4285f4;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Custom styles for react-calendar */
.react-calendar {
    width: 100%;
    background-color: #fff;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 10px;
    background: none;
    border: none;
    color: #333;
}

.react-calendar__tile--now {
    background: #ffeb3b;
    color: #000;
}

.react-calendar__tile--active {
    background: #4285f4;
    color: white;
}

.react-calendar__tile--hasActive {
    background: #4285f4;
    color: white;
}

.react-calendar__tile--hover {
    background: #e0e0e0;
}