.social-media-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.social-media-container h1 {
    text-align: center;
    color: #333;
}

.social-media-container p {
    text-align: center;
    color: #666;
}

.social-media-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.account-info {
    display: flex;
    align-items: center;
}

.account-info h2 {
    margin: 0;
    color: #333;
}

.account-info .status {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.status.connected .status-indicator {
    background-color: green;
}

.status.not-connected .status-indicator {
    background-color: red;
}

.account-actions {
    display: flex;
    gap: 10px;
}

.account-actions button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.account-actions button:hover {
    background-color: #0056b3;
}