.chat-room-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.chat-room-list li {
    height: 100px;
    background: var(--color-gray);
    flex: 1 1 calc(50% - 4px);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
}

nav {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    flex-wrap: wrap;
}

nav a {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px;
    font-weight: bold;
    transition: color 0.3s ease;
}

nav a:hover {
    color: #0056b3;
}

.profile {
    display: flex;
    align-items: center;
}

.profile img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.profile label {
    font-weight: bold;
    margin-right: 10px;
}

.profile button {
    padding: 5px 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.profile button:hover {
    background-color: #357ae8;
}

.container {
    padding: 100px 20px 20px; /* Adjusted padding to account for fixed nav bar */
}

/* Responsive styles */
@media (max-width: 768px) {
    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile {
        margin-top: 10px;
    }

    nav a {
        margin: 5px 0;
    }

    .container {
        padding: 120px 20px 20px; /* Adjusted padding to account for fixed nav bar */
    }
}